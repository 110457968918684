/**
 * Login Page
 */

// Core imports
import React from 'react';
import PropTypes from 'prop-types';

// Style, SEO and settings
import Helmet from 'react-helmet';
import { Col, css, Grid, Row, up, styled, withTheme } from '@smooth-ui/core-sc';

// Additional Components/Containers
import LoadAsync from '_platform/src/utils/LoadAsync';
import PublicComponent from '_platform/src/utils/PublicComponent';
import getReturnUrlPath from '_platform/src/utils/getReturnUrlPath';

import { withSettings } from 'containers/WebApp/SettingsContext';
import bgImage1x from './images/Login-page-bg@1x.png';
import bgImage2x from './images/Login-page-bg@2x.png';
import logo from './images/Logo-LoginPage@2x.png';

const LoginProvider = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginProvider" */ '_platform/src/containers/LoginProvider/LoginProvider'
  )
);
const LoginForm = LoadAsync(() =>
  import(
    /* webpackChunkName: "loginForm" */ '_platform/src/components/LoginForm/LoginForm'
  )
);

const PageContainer = styled.div`
  align-items: center;
  background-color: #0e1934;
  background-image: url(${bgImage1x});
  background-position: center center;
  background-repeat: repeat;
  background-size: cover;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  justify-content: center;
  @media only screen and (min-device-pixel-ratio: 1.5),
    only screen and (min-resolution: 144dpi),
    only screen and (min-resolution: 1.5dppx) {
    background-color: #0e1934;
    background-image: url(${bgImage2x});
  }
`;

const LoginLogo = styled.h1`
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 1rem;
  padding-top: 0;
  img {
    height: auto;
    width: 100%;
  }
`;

const IntroContainer = styled.div`
  background-color: rgba(14, 25, 52, 0.6);
  box-sizing: border-box;
  color: #fff;
  margin: 1rem;
  h2 {
    font-size: 30px;
  }
  p {
    color: #fff;
  }
`;

const LoginContainer = styled.div`
  font-weight: bold;
  padding: 2rem;

  ${up(
    'lg',
    css`
      padding-left: 2rem;
      min-width: 300px;
    `
  )};
`;

const InfoContainer = styled.div`
  padding: 1rem;

  button {
    background-color: #fff;
    color: #0e1934;

    &:hover {
      background-color: #fff;
      color: #db2027;
    }
  }

  .reset-password {
    a {
      color: #fff;
      font-weight: bold;
      &:hover {
        text-decoration: none;
      }
      &:visited {
        color: #fff;
      }
    }
  }
`;

const LoginPage = ({ settings }) => (
  <PublicComponent
    redirectOnError={
      // Redirect to returnUrl, if it's not the same as loginPagePath
      (getReturnUrlPath !== settings.settingsApp.loginPagePath &&
        getReturnUrlPath) ||
      '/'
    }
  >
    <Helmet>
      <title>Login</title>
      {/*<meta name="description" content="Page description" />*/}
    </Helmet>

    <PageContainer>
      <Grid className="page-container">
        <IntroContainer>
          <Grid>
            <Row justifyContent="center">
              <Col xs={12} sm={10} lg={6}>
                <LoginContainer>
                  <LoginLogo className="text--center">
                    <img
                      src={logo}
                      alt={
                        settings.settingsApp && settings.settingsApp.siteName
                          ? settings.settingsApp.siteName
                          : 'ProgramName'
                      }
                    />
                  </LoginLogo>
                </LoginContainer>
              </Col>
              <Col xs={12} sm={10} lg={6} olg={0}>
                <InfoContainer>
                  <h4 className="text--uppercase">Konnichiwa Helsinki!</h4>
                  <p>
                    Get ready to say Konnichiwa to Tokyo and Helsinki!
                    Introducing your exclusive 2024/25 Australian distributor
                    growth incentive program!
                  </p>
                  <p>
                    The buying period runs from 3rd June 2024 to 1st June 2025.
                  </p>
                  <p>Login for more details and to track your progress.</p>
                  <LoginProvider disableMeta>
                    <LoginForm formLabels={{ labelUserName: 'Username' }} />
                  </LoginProvider>
                </InfoContainer>
              </Col>
            </Row>
          </Grid>
        </IntroContainer>
      </Grid>
    </PageContainer>
  </PublicComponent>
);

LoginPage.propTypes = {
  settings: PropTypes.object.isRequired,
};

export default withTheme(withSettings(LoginPage));
